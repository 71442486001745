<template>
  <OrganisationsTable />
</template>

<script>
import OrganisationsTable from '@/components/OrganisationsTable'

export default {
  name: 'Home',
  components: {
    OrganisationsTable,
  },
}
</script>
