<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      <template>
        <v-toolbar-title>Organisasjoner</v-toolbar-title>
      </template>
      <v-spacer />
      <v-btn
        v-if="organisations.length"
        icon
        color="white"
        :disabled="loading"
        @click="exportCsv"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-toolbar>
    <v-progress-linear v-if="loading" indeterminate />
    <v-row class="mt-2 mx-2 mb-4">
      <v-col cols="12" md="6" lg="2" xl="3">
        <v-autocomplete
          v-model="search.municipalities"
          :items="municipalities"
          label="Kommune"
          clearable
          multiple
          small-chips
          deletable-chips
          :disabled="loading"
        />
      </v-col>
      <v-col cols="12" md="6" lg="2" xl="3">
        <v-autocomplete
          v-model="search.industries"
          :items="industries"
          label="Næringskode 1"
          clearable
          multiple
          small-chips
          deletable-chips
          :disabled="loading"
        />
      </v-col>

      <v-col cols="12" md="6" lg="2" xl="1">
        <v-autocomplete
          v-model="search.organisationtypes"
          :items="organisationtypes"
          label="Org.form"
          clearable
          multiple
          small-chips
          deletable-chips
          :disabled="loading"
        />
      </v-col>

      <v-col cols="12" md="6" lg="2" xl="1">
        <v-text-field
          v-model="search.employeeCount.from"
          type="number"
          label="Ansatte fra"
          clearable
          :disabled="loading"
        />
        <v-text-field
          v-model="search.employeeCount.to"
          type="number"
          label="Ansatte til"
          clearable
          :disabled="loading"
        />
      </v-col>
      <v-col cols="12" md="6" lg="2" xl="2">
        <v-text-field
          v-model="search.registrationDate.from"
          type="date"
          label="Reg.dato fra"
          clearable
          :disabled="loading"
        />
        <v-text-field
          v-model="search.registrationDate.to"
          type="date"
          label="Reg.dato til"
          clearable
          :disabled="loading"
        />
      </v-col>

      <v-btn
        class="ma-4"
        color="success"
        fab
        small
        :disabled="loading"
        @click="fetchData"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-row>

    <v-data-table
      v-if="hasFetchedData"
      :items="organisations"
      item-key="organisation_id"
      :server-items-length="totalOrganisations"
      :loading="loading"
      :disable-pagination="loading"
      :disable-sort="loading"
      v-bind.sync="organisationsTable"
      loading-text="Oppdaterer"
      no-data-text="Fant ingen organisasjoner"
      @update:sort-desc="fetchData"
      @update:sort-by="fetchData"
      @update:page="fetchData"
      @update:items-per-page="fetchData"
    >
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
import { ExportToCsv } from 'export-to-csv'

export default {
  components: {},
  mixins: [],
  data() {
    return {
      loading: false,
      organisations: [],
      industries: [],
      municipalities: [],
      organisationtypes: [],
      totalOrganisations: 0,
      hasFetchedData: false,
      search: {
        industries: [],
        municipalities: [],
        organisationtypes: [],
        employeeCount: {
          from: null,
          to: null,
        },
        registrationDate: {
          from: null,
          to: null,
        },
      },
      organisationsTable: {
        headers: [
          { text: 'Navn', value: 'navn' },
          { text: 'Org.nr', value: 'organisasjonsnummer' },
          { text: 'Reg.dato', value: 'registreringsdatoEnhetsregisteret' },
          { text: 'Org.form', value: 'organisasjonsform_kode' },
          { text: 'Næringskode', value: 'naeringskode1_kode' },
          { text: 'Beskrivelse', value: 'naeringskode1_beskrivelse' },
          { text: 'Ansatte', value: 'antallAnsatte' },
          { text: 'Epost', value: 'emailaddress' },
        ],
        footerProps: {
          'items-per-page-text': 'Vis:',
          'items-per-page-options': [10, 25, 50, 100, 200],
        },
        options: {
          itemsPerPage: 10,
          page: 1,
          sortBy: ['navn'],
          sortDesc: [false],
        },
      },
    }
  },
  async created() {
    this.loading = true

    try {
      const [municipalities, industries, organisationtypes] = await Promise.all(
        [
          axios.get('/municipalities'),
          axios.get('/industries'),
          axios.get('/organisationtypes'),
        ]
      )

      this.municipalities = municipalities.data.map((row) => {
        return row.name || 'Ingen'
      })

      this.industries = industries.data.map((row) => {
        return row.name || 'Ingen'
      })

      this.organisationtypes = organisationtypes.data.map((row) => {
        return row.name
      })
    } catch (err) {
      console.log('err:', err)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async fetchData() {
      if (this.loading) return
      this.loading = true
      this.hasFetchedData = true

      const query = this.getSearchQuery()
      const order = `${this.organisationsTable.options.sortBy[0]}.${
        this.organisationsTable.options.sortDesc[0] ? 'desc' : 'asc'
      }`

      try {
        const response = await axios.get('/organisations', {
          params: {
            per_page: this.organisationsTable.options.itemsPerPage,
            page: this.organisationsTable.options.page,
            order: order,
            q: query,
          },
        })

        this.organisations = response.data.data
        this.totalOrganisations = response.data.total
      } catch (err) {
        console.log('err:', err)
      } finally {
        this.loading = false
      }
    },
    async exportCsv() {
      if (!this.organisations.length) return

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        filename: 'bedrifter',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      }

      this.loading = true
      try {
        const response = await axios.get('/organisations', {
          params: {
            q: this.getSearchQuery(),
          },
        })

        const organisations = response.data.map((organisation) => {
          organisation.epostadresse = organisation.emailaddress
          delete organisation.emailaddress
          delete organisation.email_last_updated
          delete organisation.open_data_last_updated
          return organisation
        })

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(organisations)
      } catch (err) {
        console.log('err:', err)
      } finally {
        this.loading = false
      }
    },
    getSearchQuery() {
      const query = {
        konkurs: false,
        emailaddress: {
          compare: 'exists',
        },
      }

      if (this.search.municipalities.length) {
        query.forretningsadresse_kommune = this.search.municipalities
      }

      if (this.search.industries.length) {
        query.naeringskode1_beskrivelse = this.search.industries
      }

      if (this.search.organisationtypes.length) {
        query.organisasjonsform_kode = this.search.organisationtypes
      }

      if (this.search.employeeCount.from && this.search.employeeCount.to) {
        query.antallAnsatte = {
          compare: 'between',
          value: [this.search.employeeCount.from, this.search.employeeCount.to],
        }
      } else if (this.search.employeeCount.from) {
        query.antallAnsatte = {
          compare: 'gte',
          value: this.search.employeeCount.from,
        }
      } else if (this.search.employeeCount.to) {
        query.antallAnsatte = {
          compare: 'lte',
          value: this.search.employeeCount.to,
        }
      }

      if (
        this.search.registrationDate.from &&
        this.search.registrationDate.to
      ) {
        query.registreringsdatoEnhetsregisteret = {
          compare: 'between',
          value: [
            this.search.registrationDate.from,
            this.search.registrationDate.to,
          ],
        }
      } else if (this.search.registrationDate.from) {
        query.registreringsdatoEnhetsregisteret = {
          compare: 'gte',
          value: this.search.registrationDate.from,
        }
      } else if (this.search.registrationDate.to) {
        query.registreringsdatoEnhetsregisteret = {
          compare: 'lte',
          value: this.search.registrationDate.to,
        }
      }

      return query
    },
  },
}
</script>
