<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center text-h6">
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.svg"
          transition="scale-transition"
          width="40"
        />
        Breggeriet
      </div>
      <v-spacer />
      <v-btn v-if="loggedIn" icon @click="logOut">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container class="pa-8">
        <router-view v-if="loggedIn" />
        <LoginForm v-else />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoginForm from '@/components/LoginForm'

export default {
  name: 'App',
  components: { LoginForm },
  data: () => {
    return {
      loggedIn: localStorage.getItem('token') || false,
    }
  },
  created() {
    document.title = 'Breggeriet'
  },
  methods: {
    logOut() {
      localStorage.removeItem('token')
      this.$router.go()
    },
  },
}
</script>
